// Generated by Framer (7af8adc)

import { addFonts, cx, CycleVariantState, useActiveVariantCallback, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["LE9AW6QxC"];

const variantClassNames = {LE9AW6QxC: "framer-v-lzqsrj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "LE9AW6QxC", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "LE9AW6QxC", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear5pl1od = activeVariantCallback(async (...args) => {
setVariant("AobwNnhBl")
})

useOnVariantChange(baseVariant, {default: onAppear5pl1od})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-mqVHy", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-lzqsrj", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"LE9AW6QxC"} ref={ref} style={{...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-mqVHy [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mqVHy * { box-sizing: border-box; }", ".framer-mqVHy .framer-gixxwv { display: block; }", ".framer-mqVHy .framer-lzqsrj { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 115px; justify-content: center; padding: 0px 0px 0px 0px; position: relative; width: 550px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-mqVHy .framer-lzqsrj { gap: 0px; } .framer-mqVHy .framer-lzqsrj > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-mqVHy .framer-lzqsrj > :first-child { margin-left: 0px; } .framer-mqVHy .framer-lzqsrj > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 115
 * @framerIntrinsicWidth 550
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerHfTtJtKRJ: React.ComponentType<Props> = withCSS(Component, css, "framer-mqVHy") as typeof Component;
export default FramerHfTtJtKRJ;

FramerHfTtJtKRJ.displayName = "variant 2";

FramerHfTtJtKRJ.defaultProps = {height: 115, width: 550};

addFonts(FramerHfTtJtKRJ, [])